import Swiper from 'swiper/bundle';

const swiper = new Swiper(".mySwiper", {
  slidesPerView: 1,
  loop: true,
  spaceBetween: 30,
  autoplay: {
    delay: 2500,
    disableOnInteraction: false,
  },
  breakpoints: {
    640: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 30,
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

window.addEventListener("load", function () {
  let menu = document.querySelector("#header").offsetTop;
  document.addEventListener('scroll', function () {
    if (document.body.scrollTop > menu || document.documentElement.scrollTop > menu) {
      document.querySelector("#header").classList.add("menu-fixo");
    } else {
      document.querySelector("#header").classList.remove("menu-fixo");
    }
  });
})

const btnMenu = document.querySelector("#btn-menu");
const ulMenu = document.querySelector("#list-menu-header");
btnMenu.addEventListener("click", Event =>{
  btnMenu.classList.toggle("active")
  ulMenu.classList.toggle("active")
})
